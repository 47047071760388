import React from 'react'

export default function EmailSentPopUp({setRenderPopUp}) {
	return (
		<div className='emailSentPopUp'>
		<article>
			<h1>Your message has been sent.</h1>
			<p>We will get in touch promptly.</p>
			<p>Thank you!</p>
			<span onClick={()=>setRenderPopUp(false)}>x</span>
			</article>
		</div>
	)
}