import logo from './logo.svg';
import './App.css';
import {useState,PixelRatio} from 'react'

import axios from "./config.js"

// images
import BlueFutures from './images/ED-red-BluFut-VIVA.jpg';
import evrisposs1 from './images/SCIRENA-OP_41.jpg';
import evrisposs2 from './images/SCIRENA-OP_5.jpg';
import vivaCast1 from './images/vivacasting/8104_resized.jpg';
import vivaCast2 from './images/vivacasting/8119.jpg';
import vivaCast3 from './images/vivacasting/8342_resized.jpg';
import vivaCast4 from './images/vivacasting/8408.jpg';
import vivaCast5 from './images/vivacasting/8427.jpg';
import vivaCast6 from './images/vivacasting/8525.jpg';
import vivaCast7 from './images/vivacasting/8594.jpg';
import vivaCast8 from './images/vivacasting/8620_resized.jpg';
import vivaCast9 from './images/vivacasting/8681_resized.jpg';
import appl from  './images/10_AdanRoss.jpg';
import tele from './images/VIVA-3D-TVE2.jpg';
import dre from './images/DarioRamonEdgar.jpg';
import cam from './images/IMG_1160.jpg';
import cap from './images/edgarandcaptain3d.jpg';
import carmen from './images/CarmenJulianEd.jpg';
import shoot from './images/20110807-IMG_9863.jpg';
import win from './images/Winners2.jpg';

import EmailSentPopUp from './EmailSentPopUp.js'


function App() {
  const [navMobActive,setNavMobActive] = useState(false);

  const [renderPopUp, setRenderPopUp] = useState(false)

  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: '',
    which:'contact',
    source:'viva3dfilms.com'
  })

  const [castingForm, setCastingForm] = useState({
    name: '',
    email: '',
    phone: '',
    which:'casting',
    source:'viva3dfilms.com'
  })


  const handleContactForm = (e) => {
    setContactForm({...contactForm, [e.target.name]: e.target.value})
  }

  const submitContactForm = async (e) => {
    e.preventDefault();
    setRenderPopUp(true)
    if(contactForm.lastname){return}else{
      try {
        const res = await axios.post('/sendEmail', contactForm);
      } catch (error) {
        console.log(error);
      }
    }
    setContactForm({
      name: '',
      email: '',
      message: '',
      which:'contact',
      source:'viva3dfilms.com'
    })
  }

  const handleCastingForm = (e) => {
    setCastingForm({...castingForm, [e.target.name]: e.target.value})
  }

  const submitCastingForm = async (e) => {
    e.preventDefault();
    setRenderPopUp(true)
    if(castingForm.lastname){return}else{
    try {
      const res = await axios.post('/sendEmail', castingForm);
    } catch (error) {
      console.log(error);
    }
  }
    setCastingForm({
      name: '',
      email: '',
      phone: '',
      which:'casting',
      source:'viva3dfilms.com'
    })
  }


  return (
    <div className="App">
    {renderPopUp && <EmailSentPopUp setRenderPopUp={setRenderPopUp}/>}
    <header className="App-header">
    <h1>VIVA3DFILMS™</h1>
    <p>eVRything is possible</p>
    </header>
    <nav className='navDesk'>
    <ul>
    <li><a href='#blueFutures'>Blue Futures</a></li>
    <li><a href='#evrythingispos'>eVRything is possible</a></li>
    <li><a href='#vivacasting'>VIVA Casting</a></li>
    <li><a href='#threeD'>3D</a></li>
    <li><a href='#services'>Services</a></li>
    <li><a href='#applications'>Applications</a></li>
    {/*<li>3DWorks®</li>*/}
    <li><a href='#contact'>Contact</a></li>
    </ul>
    </nav>
    <nav className='navMob' onClick={()=>setNavMobActive(!navMobActive)}>
    <span >|||</span>
    <ul className={navMobActive?'active':''}>
    <li><a href='#blueFutures'>Blue Futures</a></li>
    <li><a href='#evrythingispos'>eVRything is possible</a></li>
    <li><a href='#vivacasting'>VIVA Casting</a></li>
    <li><a href='#threeD'>3D</a></li>
    <li><a href='#services'>Services</a></li>
    <li><a href='#applications'>Applications</a></li>
    {/*<li>3DWorks®</li>*/}
    <li><a href='#contact'>Contact</a></li>
    </ul>
    </nav>

    <figure>
    <img src={BlueFutures} alt="Blue Futures"/>
    <figcaption>Edgar González & David Sáez filming in the Red Sea. Picture by Josep Loaso &copy;2015</figcaption>
    </figure>
    <main>
    <section id='blueFutures'>
    {/*<h2>Blue Futures</h2>*/}
    <h2>Blue Futures</h2>
    <div className='grid2 contentContainer'>
    <article>
    <p>Our oceans are not only places of natural beauty.</p>
    <p>They provide us with food and oxygen and their coral reefs protect our coastlines from high energy waves.</p>
    <p>But dare we imagine a future in which a growing world population lives compatibly with these fragile marine ecosystems?</p>
    <p>Protecting rather than damaging them?</p>
    <p>Thankfully the answer is yes.</p>
    <p>Progress is being made at local and international levels by people who believe that we can create a better tomorrow.</p>
    <p>Our understanding of the challenges our oceans face is growing; the technology to overcome them is developing; and new models of business, in which those solutions can thrive, are emerging.</p>
    <p>Clément Pourtal and Benjamin Marais are embarking on a 2 year journey around the world to witness these solutions first hand.</p>
    <p>Supported by an expert team of scientists and filmmakers, they will be sharing their findings on social media with daily news and updates, short interviews, videos and high impact pictures.</p>
    </article>
    <div className='video-container'>
    <iframe src="https://www.youtube.com/embed/j35VSh8bLPg?si=e6ckiIPF9jcz4_Gd" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
    </div>

    </section>


    {/*<hr/>*/}
    

    <section id='evrythingispos'>
    <h2>eVRything is possible</h2>
    <div className='contentContainer'>
    <div className='grid2'>
    <figure>
    <img src={evrisposs1} alt="eVRything is possible"/>
    </figure>

    <figure>
    <img src={evrisposs2} alt="eVRything is possible"/>
    <figcaption>Nicolas Gilbert of Oceanica Prod filming 360º. Pictures by Alexis Rosenfeld &copy;2016</figcaption>
    </figure>
    </div>
    <h3>Virtual Reality (VR)</h3>
    <div className='grid2'>
    <div>
    <p>A generic term to describe immersive experiences of multimedia that recreate a Virtual environment which is presented to our senses in a way that allow us to interact in that different Reality.</p>
    <p>That new Virtual Reality can be originated and presented in various ways as follows;</p>
    <p>360° video recordings of a real-world scene, where the view in every direction is recorded at the same time. During playback the viewer has control of the viewing direction. Documentaries, music videos, etc are examples of this category.</p>
    </div>
    <div className='video-container'>
    <iframe src="https://www.youtube.com/embed/ZoclErPNQRQ?si=uCF7ZAkHlYen5z_T" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
    </div>


    <h3>Computer-generated VR (CG VR)</h3>
    <p>Opposite to filming the real world on 360º video, CG VR is an immersive experience created entirely from computer-generated content. Games and simulators are examples of this.</p>
    <h3>Augmented Reality (AR)</h3>
    <p>A live view of a physical, real-world environment enhanced by adding computer-generated content such graphics, sounds, videos or GPS data. Seen on commercial and marketing applications but also on sports, education, etc.</p>
    <p>All these immersive experiences are individually a speciality on their own right but the possibilities of cleverly blending 360° video, CG and AR in applications as diverse as the worlds of entertainment, medicine, the arts, etc are endless.</p>
    <p>VIVA3DFILMS in partnership with Oceanica Prod and Blue Finance are currently working on a multimedia project on marine conservation called Blue Futures.</p>
    <p>Enjoy Virtual Reality.</p>
    </div>
    </section>


    {/*<hr/>*/}
    

    <section id='vivacasting'>
    <h2>VIVA Casting</h2>
    <div className='contentContainer'>

    <div className='flex3'>
    <img src={vivaCast1} alt="VIVA Casting"/>
    <img src={vivaCast2} alt="VIVA Casting"/>
    <img src={vivaCast3} alt="VIVA Casting"/>
    <img src={vivaCast4} alt="VIVA Casting"/>
    <img src={vivaCast5} alt="VIVA Casting"/>
    <img src={vivaCast6} alt="VIVA Casting"/>
    <img src={vivaCast7} alt="VIVA Casting"/>
    <img src={vivaCast8} alt="VIVA Casting"/>
    <figure>
    <img src={vivaCast9} alt="VIVA Casting"/>
    <figcaption>All images &copy; {(new Date()).getFullYear()} VIVA3DFILMS & Edgar Gonzalez. All rights reserved</figcaption>
    </figure>

    </div>

    <p>VIVA Casting – It All Starts With A Look</p>
    <p>This new and exciting addition to VIVA3DFILMS will initially showcase local talent in Sitges as well as act as a bridge between agencies in other cities around the world.</p>
    <p>We like people. They’re our speciality. Our talented group of men, women and children, including actors and dancers, are eager to work with you.</p>
    <p>Our knowledge of the industry is second to none, spanning as it does a healthy few decades and covering many skills in the film, music and performing arts scene. We are confident we can help you find the artist you need while matching our clients with the roles and projects most ideally suited to their skills.</p>
    <p>Our aim at VIVA3DFILMS is to ensure the correct exposure of talent to the right production channels in order to maximise both talent and client opportunities.</p>
    <p>If you would like to pre-register please fill out the form below and we’ll get straight back to you.</p>

    <form className='flexCont' onChange={handleCastingForm} onSubmit={submitCastingForm}>
    <label htmlFor="name">Name:</label>
    <input value={castingForm.name} type="text" id="name" name="name" required />
    <input name='lastname' placeholder='Please enter your lastname' type='hidden' value={castingForm.lastname} />
    <label htmlFor="email">Email:</label>
    <input value={castingForm.email} type="email" id="email" name="email" required />
    <label htmlFor="phone">Phone:</label>
    <input value={castingForm.phone} type="text" id="phone" name="phone" required />
    <button>Send</button>
    </form>

    </div>

    </section>

    {/*<hr/>*/}

    <section id='about'>
    <h2>About</h2>
    <div className='grid2 contentContainer'>
    <article>
    <p>At VIVA3DFILMS™ we’re positioning ourselves at the forefront of a new digital revolution. We’d like to think of ourselves as visionaries, we believe in 3D. We’re creative and passionate filmmakers and we like to experiment and discover. The rapid developments we’re witnessing in the last few years in the realm of 3D are vertiginous. Filming, editing and projecting are all digitally produced.</p>

    <p>It’s thanks to today’s digital technology that shooting in 3D is becoming a reality to stay. Never before was it as practical to acquire the dual footage required to produce 3D. More importantly, we believe that to produce compelling 3D content with good storytelling is fundamental to reach audiences and to entice them to watch more 3D films.</p>
    </article>
    <div className='video-container'>
    <iframe src="https://www.youtube.com/embed/qHI8usZpCho?si=znD_Q2hmQgKPTDLa" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
    </div>
    </section>

    {/*<hr/>*/}

    <section id='threeD'>
    <h2>3D</h2>
    <div className='contentContainer'>
    <h3>3D - S3D - CG3D</h3>
    <p>The S in S3D is actually redundant because Stereo means ‘Solid’ i.e. 3D but it’s generally used to differentiate the computer generated 3D and the 3D achieved by shooting with two cameras or lenses. </p>
    <h3>How it works</h3>
    <p>To produce 3D we shoot with two cameras plus 1 rig, or 1 camera with two lenses, the different points of view between the right eye and the left eye are used to recreate the depth. To do that, we shoot with two synchronized cameras, then we send the image from the left camera to the left eye, and the image from the right camera to the right eye. There are two crucial factors when shooting 3D: the interaxial, which is the distance between the centre of the lenses of the two cameras, and the convergence, which is the angle at which we position one camera with respect to the other one.</p>
    <p>To hold and align the cameras, we use a 3D-Rig. There are two main types of 3D-rigs:</p>
    <p>The side-by-side rig, where the cameras are sitting, obviously, side by side. This kind of 3D-rig allows large interaxials, but not small interaxials (because of the physical size of the cameras). This is mostly useful for large landscape shots.</p>
    <p>The mirror rig, or beamsplitter rig. One camera films through a semi-transparent mirror, and the other films the reflection in the mirror. These rigs allow small and medium interaxials, useful for most shots.</p>
    <p>Cameras must be accurately aligned, must have exactly the same brightness and color. They must be perfectly in sync, frame-wise and even scan-wise (an external sync box, plugged in to the Genlock inputs from the cameras, and is very often used to do just that).</p>
    <h3>How to watch 3D?</h3>
    <p>We have to use glasses for at least first generation 3D TV screens. The screens that don’t need glasses do exist -they’re called autostereoscopic screens- are still expensive and still have room for improvement. Having said that, look around you there are already a growing cadre of products, from TV screens to personal computers, cameras, telephones, video games, that use existing technology, which still works quite well for the moment.</p>
    <div className='grid3'>
    <figure>
    <img src={win} alt="VIVA3DFILMS™ congratulates James Uren and his team at Ravensbourne College in London for the Special Mention Award for 'Afterlight' at DOGVILLE 3D Film Festival 2012 in Viladecans Barcelona"/>
    <figcaption>VIVA3DFILMS™ congratulates James Uren and his team at Ravensbourne College in London for the Special Mention Award for 'Afterlight' at DOGVILLE 3D Film Festival 2012 in Viladecans Barcelona</figcaption>
    </figure>
    <figure>
    <img src={carmen} alt="Julian Napier and Edgar Gonzalez at Dogville 2012 talking 'Carmen in 3D', a RealD, Royal Opera House and PLF Production"/>
    <figcaption>Julian Napier and Edgar Gonzalez at Dogville 2012 talking 'Carmen in 3D', a RealD, Royal Opera House and PLF Production</figcaption>
    </figure>
    <figure>
    <img src={shoot} alt="Shooting Afterlight on set at Ravensbourne College, London."/>
    <figcaption>Shooting Afterlight on set at Ravensbourne College, London.</figcaption>
    </figure>
    </div>
    </div>
    </section>



    {/*<hr/>*/}

    <section id='services'>
    <h2>Services</h2>
    <div className='contentContainer grid3'>
    <p><strong>Technical:</strong> We have access, through a whole international network of providers, to the latest cameras, rigs, screens, software, etc. to produce any type of film.</p>
    <p><strong>Training:</strong> We’d like to share our knowledge and experience with genuine 3D visionary people. If you’d like to learn more about 3D from a technical point of view, we’ll cater for your specific needs such as training personnel, explaining and showing clients how it works.</p>
    <p><strong>Consulting:</strong> If you have a project, a single idea or simply want to find out how 3D can benefit your business please call us to make an appointment. It makes sense ‘starting to think in 3D’.</p>
    <p className='spanAcrossGrid'>We’ll help you to make it a real cost effective investment throughout the whole process of production.</p>

    <figure>
    <img src={cap} alt="VIVA Casting"/>
    <figcaption>Edgar and Captain 3D at the advanced 3D training at Twickenham Studios, London. <a href='https://www.youtube.com/watch?v=rnvC3AElxak' target='_blank'>Watch Captain 3D on YouTube</a></figcaption>
    </figure>
    <figure>
    <img src={dre} alt="VIVA Casting"/>
    <figcaption>Edgar with Dario and Ramon from Kronomav at Camp Nou, Barcelona for live 3D football broadcast.</figcaption>
    </figure>
    <figure>
    <img src={cam} alt="VIVA Casting"/>
    <figcaption>Kronomav side by side rig.</figcaption>
    </figure>

    </div>
    </section>    

    {/*<hr/>*/}

    <section id='applications' >
    <h2>Applications</h2>
    <div className='contentContainer'>
    <p>Hundreds of millions are being spent on the technology and promotion of 3D in films, TV, telephones, games, tablets, etc.</p>
    <h3> Marketing / Publicity</h3>
    <p>3D film offers companies the best way to display and promote their products and services. It’s the most realistic, sophisticated and high-touch way to reach discerning clients.</p>
    <p>The result for viewers and potential clients: spectacular imagery, memorable experiences, and a value driver for sales.</p>
    <h3>Entertainment</h3>
    <h3>TV Productions</h3>
    <p>Sports, special events, drama series, documentaries, etc</p>
    <h3>Cinema</h3>
    <p>Films in 3D</p>
    <h3>Medical and scientific research and development</h3>
    <p>3D is being used as a powerful tool in operating theatres for more accurate and specialized surgical procedures.</p>
    <div className='grid2'>
    <figure>
    <img src={appl} alt="Charlotte, Adan and Edgar at the launch of Animo Minimo 3D Video at Sala Apolo in Barcelona"/>
    <figcaption>Charlotte, Adan and Edgar at the launch of Animo Minimo 3D Video at Sala Apolo in Barcelona. Picture by Jo Kemp &copy;2013</figcaption>
    </figure>
    <figure>
    <img src={tele} alt="Spanish national TV 'LA 2' presenting Adan Ross' Animo Minimo 3D music video."/>
    <figcaption>Spanish national TV 'LA 2' presenting Adan Ross' Animo Minimo 3D music video.</figcaption>
    </figure>
    </div>
    </div>
    </section>

    {/*<hr/>*/}
    
    <section id='contact'>
    <h2>Contact us</h2>
    <form className='flexColumn' onChange={handleContactForm} onSubmit={submitContactForm}>
    <label htmlFor="name">Name:</label>
    <input value={contactForm.name} type="text" id="name" name="name" required />
    <input name='lastname' placeholder='Please enter your lastname' type='hidden' value={contactForm.lastname} />
    <label htmlFor="email">Email:</label>
    <input value={contactForm.email} type="email" id="email" name="email" required />
    <label htmlFor="message">Message:</label>
    <textarea value={contactForm.message} id="message" name="message" required></textarea>
    <button>Send</button>
    </form>
    </section>
    </main>

    {/*<hr/>*/}
    
    <footer>
    <div className='grid2 contentContainer'>
    <div>
    <h2 className='al'>CONTACTS</h2>
    <p>Edgar Gonzalez<br/>
    Director / Stereographer<br/>
    <a href='tel:+34661839444'>+34 661 839 444</a><br/>
    <a href='mailto:edgar@viva3dfilms.com'>edgar@viva3dfilms.com</a></p>
    </div>

    <div>
    <h2>LINKS</h2>
    <ul>
    <li><a href='https://antavianafilms.com' target='_blank'>antavianafilms.com</a></li>
    <li><a href='https://www.sky.com/tv' target='_blank'>sky.com/shop/tv/3d</a></li>
    <li><a href='https://www.allthat3d.com' target='_blank'>international3dsociety.com</a></li>
    <li><a href='https://www.inition.co.uk' target='_blank'>inition.co.uk</a></li>
    <li><a href='http://advancedimagingsociety.com' target='_blank'>advancedimagingsociety.com</a></li>
    <li><a href='https://talentshots.co.uk' target='_blank'>talentshots.co.uk</a></li>
    </ul>
    </div>
    </div>
    <h4>&copy; VIVA3DFILMS™ {(new Date()).getFullYear()}</h4>
    <p className='smallText'><a href='https://undefeatedsoftware.dev/' target='_blank'>website by Undefeated Software</a></p>
    </footer>
    </div>
    );
}

export default App;
